<template>
  <p
    ref="dateRef"
    v-tooltip="{
      content: createdDate,
      onShow: () => (isFixed ? isFixedTextTruncated : isDateTruncated)
    }"
    data-cy="time-ago"
    class="text-xs text-label cursor-pointer"
    @click="handleClick"
  >
    {{ createdDate }}
  </p>
</template>
<script setup lang="ts">
import { FullStoryEvent } from "@/enums/events";
import { formatDateCustom, formatTimeAgo } from "@/helpers/formatting";
import { useElementStatus } from "@/hooks/elements";
import { useI18n } from "vue-i18n";
import { useFullStory } from "@/hooks/fullStory";
import isToday from "date-fns/isToday";
import { nextTick } from "vue";
import { computed, ref } from "vue";
const { publishEvent } = useFullStory();

const props = defineProps({
  date: {
    type: String,
    default: ""
  },
  showTimeIfToday: {
    type: Boolean,
    default: false
  },
  isFixed: {
    type: Boolean,
    default: false
  }
});

const { isTextTruncated } = useElementStatus();
const { t } = useI18n();

const showFullDateAndTime = ref(props.isFixed);
const dateRef = ref<HTMLElement | null>(null);
const isDateTruncated = ref(isTextTruncated(dateRef).value);

const isFixedTextTruncated = isTextTruncated(dateRef);

const createdDate = computed(() => {
  if (!props.date) {
    return "";
  }

  if (props.isFixed) {
    return isToday(new Date(props.date))
      ? t("EMAIL_INTEGRATION.TODAY_AT_TIME", {
          time: formatTimeAgo(props.date, props.showTimeIfToday)
        })
      : formatDateCustom(props.date, "MMMM dd, yyyy hh:mm a", true);
  }

  return showFullDateAndTime.value
    ? formatDateCustom(props.date, "MMMM dd, yyyy hh:mm a", true)
    : formatTimeAgo(props.date, props.showTimeIfToday);
});

const handleClick = () => {
  if (props.isFixed) {
    return;
  }

  showFullDateAndTime.value = !showFullDateAndTime.value;
  publishEvent(FullStoryEvent.FS_TIME_AGO_TO_FULL_TIME_EVENT, {
    date: props.date,
    isShowingFullTime: showFullDateAndTime.value
  });
  nextTick(() => {
    isDateTruncated.value = isTextTruncated(dateRef).value;
  });
};
</script>
